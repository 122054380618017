<template>
    <div class="en_add enterprise_add">
        <el-card>
            <el-page-header style="margin-bottom: 30px;" @back="goBack" />
            <el-form ref="form" :model="form" :rules="rule" label-width="110px" :inline="false" size="normal">
                <el-form-item label="企业简称" prop="name">
                    <el-col :span="8">
                        <el-input v-model="form.name" show-word-limit />
                    </el-col>
                </el-form-item>
                <el-form-item label="企业全称" prop="fullName">
                    <el-col :span="8">
                        <el-input v-model="form.fullName" />
                    </el-col>
                </el-form-item>
                <el-form-item label="企业logo" prop="logo">
                    <imgup :action="action" :banner="form.logo" :tip_text="false" @event="setImg" />
                </el-form-item>
                <el-form-item label="公司官网" prop="website">
                    <el-col :span="8">
                        <el-input v-model="form.website" />
                    </el-col>
                </el-form-item>
                <el-form-item label="实际办公地址" prop="city">
                    <div class="flex-align">
                        <div>
                            <el-select
                                v-model="form.province"
                                placeholder="省份"
                                style="width: 130px; margin-right: 12px"
                                @change="op"
                            >
                                <el-option
                                    v-for="item in province"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                />
                            </el-select>
                            <el-select v-model="form.city" placeholder="城市" style="width: 130px; margin-right: 12px">
                                <el-option
                                    v-for="item in city"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                />
                            </el-select>
                            <div class="box">
                                <el-input v-model="form.address" :maxlength="100" show-word-limit />
                            </div>
                        </div>
                        <el-button type="text" icon="el-icon-circle-plus" style="margin-left: 10px;" @click="addMore"
                            >添加更多</el-button
                        >
                    </div>
                    <office-address
                        :province="province"
                        :detail="item"
                        :index="index"
                        v-model="form.otherAddress"
                        v-for="(item, index) in form.otherAddress"
                        :key="index"
                    ></office-address>
                </el-form-item>
                <el-form-item label="公司人数" prop="people">
                    <el-select v-model="form.people" placeholder="请选择公司人数">
                        <el-option
                            v-for="(item, index) in $store.state.enterprise_config &&
                                $store.state.enterprise_config.enterprisePeople"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key * 1"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="融资情况" prop="financing">
                    <el-select v-model="form.financing" placeholder="请选择融资情况">
                        <el-option
                            v-for="(item, index) in $store.state.enterprise_config &&
                                $store.state.enterprise_config.enterpriseFinancing"
                            :key="index"
                            :label="item.value"
                            :value="item.key * 1"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <el-checkbox-group
                        v-model="form.type"
                        :max="3"
                        @change="
                            form.business = [];
                            form.subBusiness = [];
                        "
                    >
                        <el-checkbox v-for="(item, index) in typeList" :key="item.id" :label="item.id + ''">{{
                            item.name
                        }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="行业" prop="business">
                    <el-checkbox-group
                        v-model="form.business"
                        v-if="form.business.length > 3"
                        @change="form.subBusiness = []"
                    >
                        <el-checkbox v-for="(item, index) in businessList" :key="item.id" :label="item.id + ''">{{
                            item.name
                        }}</el-checkbox>
                    </el-checkbox-group>
                    <el-checkbox-group v-model="form.business" :max="3" @change="form.subBusiness = []" v-else>
                        <el-checkbox v-for="(item, index) in businessList" :key="item.id" :label="item.id + ''">{{
                            item.name
                        }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="二级行业" prop="subBusiness">
                    <el-checkbox-group v-model="form.subBusiness" :max="3">
                        <el-checkbox v-for="(item, index) in subBusinessList" :key="item.id" :label="item.id + ''">{{
                            item.name
                        }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="企业介绍">
                    <el-col :span="8" :offset="0">
                        <el-input v-model="form.intro" type="textarea" :rows="6" placeholder="请输入内容" />
                    </el-col>
                </el-form-item>
                <el-form-item label="企业简介" prop="intro">
                    <el-col :span="8" :offset="0">
                        <el-input v-model="form.companyDesc" type="textarea" :rows="6" placeholder="请输入内容" />
                    </el-col>
                </el-form-item>
                <el-form-item label="上传经典案例" prop="caseFile">
                    <el-col :span="8" :offset="0">
                        <up-file tip="请上传PDF文件，大小不可超过60M。" v-model="form.caseFile" type="pdf"></up-file>
                    </el-col>
                </el-form-item>
                <el-form-item label="上传服务bp" prop="serviceFile">
                    <el-col :span="8" :offset="0">
                        <up-file tip="请上传PDF文件，大小不可超过60M。" v-model="form.serviceFile" type="bp"></up-file>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="onSubmit">{{ id ? "修改" : "提交" }}</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import imgup from "../../components/upImg/index.vue";
import rules from "../../utils/rules";
import imgups from "../../components/uplist/index.vue";
import officeAddress from "./office-address.vue";
export default {
    components: {
        imgup,
        imgups,
        officeAddress,
    },
    data() {
        return {
            id: null,
            form: {
                name: "",
                fullName: "",
                logo: "",
                website: "",
                province: "",
                city: "",
                address: "",
                people: "",
                financing: "",
                type: [],
                business: [],
                subBusiness: [],
                serverArea: [],
                companyDesc: "",
                productDesc: "",
                productImages: [],
                serviceClose: 1,
                serviceFile: "",
                otherAddress: [],
                intro: "",
            },

            rule: {
                name: [rules.req("请输入企业简称")],
                fullName: [rules.req("请输入企业全称")],
                logo: [rules.req("请输入企业logo")],
                city: [rules.req("请输入省份和城市")],
                people: [rules.req("请输入公司人事")],
                financing: [rules.req("请输入融资情况")],
                type: [rules.req("请输入类型")],
                business: [rules.req("请输入行业")],
                serverArea: [rules.req("请输入业务区域")],
                companyDesc: [rules.req("请输入企业介绍")],
                intro: [rules.req("请输入企业简介")],
            },
            action: {
                action: "enterprise",
            },
            province: {},
            city: {},
            typeList: [],
            businessList: [],
            subBusinessList: [],
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
        };
    },
    watch: {
        "form.type"(newValue, oldValue) {
            this.getType("1");
        },
        "form.business"(newValue, oldValue) {
            this.getType("2");
        },
    },
    computed: {},
    created() {
        this.getform();
        this.getp();
        this.getType();
        this.bus.$on("new", () => {
            this.form = this.$options.data.call(this).form;
            this.id = null;
        });
    },
    methods: {
        c_img(val) {
            this.form.productImages = val;
        },
        chang(val) {
            this.form.serviceDesc = val;
        },
        goBack() {
            this.$router.go(-1);
        },
        async getp() {
            const res = await this.$store.dispatch("getProvince");
            this.province = res.data;

            //   setTimeout(()=>{
            //   },200)
        },
        addMore() {
            this.form.otherAddress.push({ province: "", city: "", address: "" });
        },
        async getc(val) {
            if (!val) this.form.city = "";
            const { data: res } = await this.$store.dispatch("getCity", this.form.province);
            this.city = res;
        },
        op() {
            this.getc();
        },
        async onSubmit() {
            var url = this.form.productImages.map((item) => item.url);
            url = url.join(",");
            let form = {
                ...this.form,
                business: this.form.business.join(","),
                serverArea: this.form.serverArea.join(","),
                type: this.form.type.toString(),
                subBusiness: this.form.subBusiness.toString(),
                productImages: url,
                otherAddress: JSON.stringify(this.form.otherAddress),
            };
            if (this.id) {
                var { data: res } = await this.$http.post("/admin/Enterprise/edit", {
                    ...form,
                    id: this.id,
                });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    history.back();
                }
            } else {
                var { data: res } = await this.$http.post("/admin/Enterprise/add", form);
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.$router.push("/enterprise_cert_list");
                }
            }
        },
        async getType(val) {
            if (val == 1) {
                var { data: res } = await this.$http.get(
                    `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.form.type.toString()}&businessId=`
                );
                if (!this.form.type.length) {
                    this.businessList = [];
                    return;
                }
                this.businessList = res.data.list;
                return;
            }
            if (val == 2) {
                var { data: res } = await this.$http.get(
                    `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.form.type.toString()}&businessId=${this.form.business.toString()}`
                );
                if (!this.form.business.length) {
                    this.subBusinessList = [];
                    return;
                }
                this.subBusinessList = res.data.list;
                return;
            }
            var { data: res } = await this.$http.get(
                "/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=0&businessId="
            );
            this.typeList = res.data.list;
        },
        async getform() {
            if (this.$route.query.id) {
                var id = this.$route.query.id;
                this.id = id;
                this.$http.get("/admin/Enterprise/getById?id=" + id).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        // console.log(res);
                        var yyds = res.data.productImages.split(",");
                        var url = [];
                        yyds.forEach((item) => {
                            if (item) {
                                url.push({
                                    name: "1",
                                    url: item,
                                });
                            }
                        });
                        this.form = {
                            ...res.data,
                            business: res.data.business.split(",").filter(Boolean),
                            serverArea: res.data.serverArea.split(",").filter(Boolean),
                            type: res.data.type.toString().split(","),
                            subBusiness: res.data.subBusiness.split(",").filter(Boolean),
                            productImages: url,
                            otherAddress: res.data.otherAddress || [],
                        };
                        this.form.province && this.getc(1);

                        console.log(this.form);
                        this.form.id = id * 1;
                        //  this.form.initViews =
                        //   Math.floor(Math.random() * (200 - 50 + 1)) + 50
                    }
                });
            }
        },
        setImg(val) {
            this.form.logo = val;
        },
    },
};
</script>

<style lang="less">
.en_add {
    .box {
        display: inline-block;
        width: 300px;
        input {
            border-color: #dcdfe6 !important;
        }
    }
}
</style>
